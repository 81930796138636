import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { Link, Stack, Text } from "basis";

import { Container, ErrorMessage, LoadingMessage } from "../../../components";
import {
  FACTOR_NAME,
  prepareWidget,
  useCustomerAuth,
} from "../../../core/auth";
import {
  LoginError,
  MerchantSettingsError,
  PointsPurchaseHeader,
  ROUTES,
  Scene,
} from "../../../layout/wallet";

import { useMerchantSettings, useThreatMetrix } from "../../../hooks";
import { useOrder } from "../../../layout/checkout";

import { logger } from "../../../core";
import { customerOktaConfig } from "../../../core/config";
import { MODE } from "../../../core/auth/constants";
import { CustomerOktaContainer } from "../../../layout/shared";

const Login = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState();

  const { startProfile, isLoading } = useThreatMetrix();
  const location = useLocation();
  const widgetRef = useRef();
  const { start } = useCustomerAuth();
  const { loading: loadingOrder, error, order, params } = useOrder();
  const [
    loadingMerchantSettings,
    errorMerchantSettings,
    merchantSettings,
  ] = useMerchantSettings(order?.merchantId);

  useEffect(() => {
    startProfile();
  }, []);

  useEffect(() => {
    if (loadingOrder) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (!widgetRef.current) {
      return;
    }

    if (!order?.transactionId) {
      return;
    }

    if (loginError) {
      return;
    }

    start();

    const widget = prepareWidget({
      oktaConfig: customerOktaConfig,
      mode: MODE.CUSTOMER,
      targetElement: widgetRef.current,
      title: "Login",
      usernamePlaceholder: "Latitude ID",
      redirectURL: `${ROUTES.POINTS_PAYMENT}${location.search}`,
      allowedFactors: [FACTOR_NAME.SMS],
      afterAuth: () => setAuthenticated(true),
      onError: (code, description) => {
        logger.error(`Wallet points login error. ${code}: ${description}`);
        setLoginError(code);
      },
    });

    return () => {
      widget.off();
      widget.remove();
    };
  }, [loadingOrder, location.search, params, order, error, start, loginError]);

  const showLoading =
    (loadingOrder || isAuthenticated || isLoading || loadingMerchantSettings) &&
    !error;

  if (showLoading) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <LoadingMessage />
      </Scene>
    );
  }

  if (error?.message) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <ErrorMessage
          type={error?.message}
          additionalParams={{
            minimumAmount: order?.minimumAmount,
            currency: order?.currency,
            urlCancel: order?.urlCancel,
            transactionId: order?.transactionId,
          }}
        />
      </Scene>
    );
  }

  if (errorMerchantSettings) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <Container margin="4 0" padding="2" maxWidth={1100}>
          <MerchantSettingsError />
        </Container>
      </Scene>
    );
  }

  return (
    <Scene
      theme={merchantSettings?.theme}
      merchantLogoURL={merchantSettings?.urlLogo}
    >
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Link href={order?.urlCancel} newTab={false}>
            &lt; Back to cart
          </Link>

          <Text align="left" textStyle="heading4">
            Pay with points
          </Text>
        </Stack>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={400}>
          <PointsPurchaseHeader
            amount={order?.amount}
            burnRate={order?.rewards?.burnRate || 0}
          />
        </Container>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="10 6" maxWidth={400}>
          <Stack gap="4">
            <Text align="left" textStyle="heading4">
              Login
            </Text>

            <Stack>
              <Text textStyle="body2" align="left">
                Enter your Latitude ID to receive an one time verification code.
                The code will be sent to the mobile number linked to your
                Latitude account.
              </Text>
            </Stack>

            {loginError && (
              <LoginError
                errorCode={loginError}
                additionalDesc="Please return to cart and select an alternative payment method."
              />
            )}

            <CustomerOktaContainer ref={widgetRef} />

            <Link
              href={`${ROUTES.POINTS_PAYMENT}${location.search}`}
              newTab={false}
            >
              Back to login
            </Link>
          </Stack>
        </Container>
      </Container>
    </Scene>
  );
};

export default Login;
